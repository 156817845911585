import React from 'react'
import classnames from 'classnames'
import { createPortal } from 'react-dom'

import styles from './styles.module.scss'

interface Props extends React.PropsWithChildren {
  isOpen: boolean
}

const MobileNavItems: React.FC<Props> = ({ children, isOpen }) => {
  const [mounted, setMounted] = React.useState(false)

  React.useEffect(() => {
    setMounted(true)

    return () => setMounted(false)
  }, [])

  const mobileNavItemsClass = classnames(styles.mobileNavItems, 'print-hide', {
    [styles.mobileNavItemsOpen]: isOpen,
  })

  return mounted
    ? createPortal(
        <div className={mobileNavItemsClass}>{children}</div>,
        document.getElementById('mobile-nav-items'),
      )
    : null
}

export default MobileNavItems
