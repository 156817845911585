import Obituary from './components/models/Obituary'
import { Case, FlowerProduct, ObituaryMemory, ObituarySnippet } from './types'

export const routes = {
  about: '/about',
  billing: (theCase: Case | ObituarySnippet, cartID: string) =>
    `${routes.flowerstore(theCase)}/checkout/${cartID}/billing`,
  obituaries: '/obituaries',
  confirmation: (theCase: Case, cartID: string) =>
    `${routes.flowerstore(theCase)}/checkout/${cartID}/confirmation`,
  contact: '/contact',
  flowerstore: (obit: ObituarySnippet | Case): string =>
    `/obituaries/${Obituary.toSlug(obit)}/flowerstore`,
  flowerstoreProduct: (obit: ObituarySnippet | Case, product: FlowerProduct): string =>
    `/obituaries/${Obituary.toSlug(obit)}/flowerstore/${product.code}`,
  home: '/',
  howWeCelebrate: '/how-we-celebrate',
  memorialTrees: (obit: ObituarySnippet | Case): string =>
    `/obituaries/${Obituary.toSlug(obit)}/memorial-trees`,
  memories: (obit: ObituarySnippet | Case): string =>
    `/obituaries/${Obituary.toSlug(obit)}/memories`,
  memory: (obit: ObituarySnippet | Case, memory: ObituaryMemory): string =>
    routes.memoryFromID(obit, memory.id),
  memoryFromID: (obit: ObituarySnippet | Case, memoryID: number): string =>
    `/obituaries/${Obituary.toSlug(obit)}/memories?memory=${memoryID}`,
  obituary: (obit: ObituarySnippet | Case): string => `/obituaries/${Obituary.toSlug(obit)}`,
  planningAhead: '/planning-ahead',
  privacyPolicy: '/privacy',
  products: '/products',
  services: (obit: ObituarySnippet | Case) => `/obituaries/${Obituary.toSlug(obit)}/services`,
  shipping: (obit: ObituarySnippet | Case, cartID: string) =>
    `${routes.flowerstore(obit)}/checkout/${cartID}`,
  termsOfUse: '/terms',
  yourGriefJourney: '/your-grief-journey',
}
