import { Case, ObituarySnippet } from '../../../types'

const toSlug = (obituary: ObituarySnippet | Case): string => {
  const { deceased, id } = obituary

  return `${deceased.first_name.replace(/\s/g, '')}-${deceased.last_name.replace(/\s/g, '')}-${id}`
}

interface Options {
  includeMiddleName?: boolean
}

const deceasedFullName = (obituary: ObituarySnippet | Case, options: Options = {}): string => {
  const { deceased } = obituary

  return [
    deceased.first_name,
    options.includeMiddleName && deceased.middle_name,
    deceased.last_name,
  ]
    .filter(Boolean)
    .join(' ')
}

const possessiveFirstName = (obituary: ObituarySnippet | Case): string => {
  const { deceased } = obituary

  return deceased.first_name.endsWith('s') ? `${deceased.first_name}'` : `${deceased.first_name}'s`
}

const Obituary = {
  deceasedFullName,
  possessiveFirstName,
  toSlug,
}

export default Obituary
