import React from 'react'
import MobileNavContext, { IMobileNavContext } from '../../contexts/MobileNavContext'

const useMobileNavContext = (): IMobileNavContext => {
  const ctx = React.useContext(MobileNavContext)

  if (!ctx.isChildOfProvider) {
    throw new Error(
      '"useMobileNavContext" must be used as a child of the flower products context provider',
    )
  }

  return ctx
}

export default useMobileNavContext
