import React from 'react'
import classnames from 'classnames'

import styles from './styles.module.scss'

interface Props extends React.PropsWithChildren {
  className?: string
}

const Wrapper: React.FC<Props> = ({ children, className }) => {
  return <div className={classnames(styles.wrapper, className)}>{children}</div>
}

const Item: React.FC<Props> = ({ children, className }) => {
  return <p className={classnames(styles.item, className)}>{children}</p>
}

const Ribbon = {
  Item,
  Wrapper,
}

export default Ribbon
