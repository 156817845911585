import React from 'react'
import Link from 'next/link'

import Footer, { SocialIcon } from '../Footer'
import { routes } from '../../routes'
import styles from './styles.module.scss'

export const FuneralHomeFooter: React.FC = () => {
  return (
    <div className="print-hide">
      <Footer.Wrapper>
        <Footer.Section>
          <Footer.Logo />
        </Footer.Section>

        <Footer.Section fill={true}>
          <Footer.Location>
            <Footer.Title>Kalas - Edgewater</Footer.Title>

            <Footer.Body>2973 Solomons Island Rd</Footer.Body>

            <Footer.Body>Edgewater, MD 21037</Footer.Body>

            <Footer.Body bold={true}>(410) 956-4488</Footer.Body>
          </Footer.Location>

          <Footer.Location>
            <Footer.Title>Kalas - Oxon Hill</Footer.Title>

            <Footer.Body>6160 Oxon Hill Rd</Footer.Body>

            <Footer.Body>Oxon Hill, MD 20745</Footer.Body>

            <Footer.Body bold={true}>(301) 567-9424</Footer.Body>
          </Footer.Location>

          <Footer.Right className={styles.right}>
            <Footer.SocialWrapper>
              <Footer.Social href="https://www.facebook.com/KalasFH" icon={SocialIcon.FACEBOOK} />

              <Footer.Social
                href="https://www.instagram.com/kalasfhc/?hl=en"
                icon={SocialIcon.INSTAGRAM}
              />

              <Footer.Social href="https://twitter.com/kalasfh?lang=en" icon={SocialIcon.TWITTER} />
            </Footer.SocialWrapper>

            <div className={styles.bottomRight}>
              <Footer.Small>
                &copy;2022 Kalas Funeral Home &amp; Crematory. All rights reserved.
              </Footer.Small>

              <div className={styles.bottomRightInner}>
                <Footer.Small>
                  <Link href={routes.privacyPolicy}>
                    <a className={styles.link} rel="noopener" target="_blank">
                      Privacy Policy
                    </a>
                  </Link>
                </Footer.Small>

                <Footer.Small>
                  <Link href={routes.termsOfUse}>
                    <a className={styles.link} rel="noopener" target="_blank">
                      Terms of Use
                    </a>
                  </Link>
                </Footer.Small>

                <Footer.Small>
                  Site powered by{' '}
                  <Link href="https://getrevelry.com/">
                    <a className={styles.link} rel="noopener" target="_blank">
                      Revelry
                    </a>
                  </Link>
                </Footer.Small>
              </div>
            </div>
          </Footer.Right>
        </Footer.Section>
      </Footer.Wrapper>
    </div>
  )
}
