import { NavItem } from '../../types'
import { routes } from '../../routes'

const navItems: NavItem[] = [
  {
    item: 'Obituaries',
    location: routes.obituaries,
    activeMatch: new RegExp(`${routes.obituaries}/*`, 'i'),
  },
  {
    item: 'How We Celebrate',
    location: routes.howWeCelebrate,
    activeMatch: new RegExp(routes.howWeCelebrate, 'i'),
  },
  {
    item: 'Planning Ahead',
    location: routes.planningAhead,
    activeMatch: new RegExp(routes.planningAhead, 'i'),
  },
  {
    item: 'Your Grief Journey',
    location: routes.yourGriefJourney,
    activeMatch: new RegExp(routes.yourGriefJourney, 'i'),
  },
  {
    item: 'About',
    location: routes.about,
    activeMatch: new RegExp(routes.about, 'i'),
  },
  {
    item: 'Contact',
    location: routes.contact,
    activeMatch: new RegExp(routes.contact, 'i'),
  },
]

export default navItems
