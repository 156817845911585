import React from 'react'
import classnames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { Spacer } from '@revelrygroup/components'

import styles from './styles.module.scss'

export enum SocialIcon {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
}

const images: { [k in SocialIcon]: { alt: string; src: string } } = {
  [SocialIcon.FACEBOOK]: { alt: 'Facebook', src: '/icon-facebook.png' },
  [SocialIcon.INSTAGRAM]: { alt: 'Instagram', src: '/icon-instagram.png' },
  [SocialIcon.TWITTER]: { alt: 'Twitter', src: '/icon-twitter.png' },
}

const Body: React.FC<React.PropsWithChildren<{ bold?: boolean }>> = ({ bold, children }) => {
  return <p className={classnames('body', styles.body, { [styles.bold]: bold })}>{children}</p>
}

const Location: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Spacer horizontalMdRight="md" verticalBottom="sm" verticalMdBottom="none">
      {children}
    </Spacer>
  )
}

const Logo: React.FC = () => {
  return (
    <div className={styles.logoWrapper}>
      <Image alt="Kalas Logo" src="/kalas-radian.png" layout="fill" />
    </div>
  )
}

const Right: React.FC<React.PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => {
  return <div className={classnames(styles.right, className)}>{children}</div>
}

const Section: React.FC<React.PropsWithChildren<{ fill?: boolean }>> = ({ children, fill }) => {
  return <div className={classnames(styles.section, { [styles.fill]: fill })}>{children}</div>
}

const Small: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <p className={classnames('small', styles.small)}>{children}</p>
}

const Social: React.FC<{ href: string; icon: SocialIcon }> = ({ href, icon }) => {
  const imgData = images[icon]

  return (
    <Link href={href}>
      <a className={styles.social} rel="noopener" target="_blank">
        <Image layout="fixed" alt={imgData.alt} src={imgData.src} height={20} width={20} />
      </a>
    </Link>
  )
}

const SocialWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <div className={styles.socialWrapper}>{children}</div>
}

const Title: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <p className={classnames('body', styles.title)}>{children}</p>
}

const Wrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <div className={styles.wrapper}>{children}</div>
}

const Footer = {
  Body,
  Location,
  Logo,
  Right,
  Section,
  Small,
  Social,
  SocialWrapper,
  Title,
  Wrapper,
}

export default Footer
