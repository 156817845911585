import React from 'react'
import classnames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { HeaderVariant } from '../../types'
import MobileNavItems from '../MobileNavItems'
import navItems from './navItems'
import styles from './styles.module.scss'
import useMobileNavContext from '../../hooks/useMobileNavContext'

interface Props {
  variant?: HeaderVariant
}

const Header: React.FC<Props> = ({ variant }) => {
  const { isOpen, toggleMobileNav } = useMobileNavContext()
  const router = useRouter()
  const handleNavClick = () => isOpen && toggleMobileNav()

  return (
    <>
      <div
        className={classnames(styles.wrapper, 'print-hide', {
          [styles.wrapperBranded]: variant === 'branded',
          [styles.wrapperBrandedLight]: variant === 'brandedLight',
          [styles.wrapperBrandedLightV2]: variant === 'brandedLightV2',
        })}
      >
        <a className={styles.logoWrapper} href="/">
          <Image alt="Kalas Logo" src="/kalas-logo-tagline.png" layout="fill" />
        </a>

        <div className={styles.mobileNavToggleWrapper}>
          <button
            className={styles.mobileNavToggle}
            onClick={() => toggleMobileNav()}
            type="button"
          >
            {isOpen ? 'Close' : 'Menu'}
          </button>
        </div>

        <nav className={styles.navDesktop}>
          <ul>
            {navItems.map((navItem) => (
              <li
                className={
                  navItem.activeMatch.test(router.pathname) ? styles.active : styles.inactive
                }
                key={navItem.location}
              >
                <Link href={navItem.location}>
                  <a onClick={handleNavClick} onKeyPress={handleNavClick} role="link" tabIndex={0}>
                    {navItem.item}
                  </a>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      <MobileNavItems isOpen={isOpen}>
        <ul>
          {navItems.map((navItem) => (
            <li key={navItem.location}>
              <Link href={navItem.location}>
                <a
                  className={
                    navItem.activeMatch.test(router.pathname) ? styles.active : styles.inactive
                  }
                  onClick={handleNavClick}
                  onKeyPress={handleNavClick}
                  role="link"
                  tabIndex={0}
                >
                  {navItem.item}
                </a>
              </Link>
            </li>
          ))}
        </ul>
      </MobileNavItems>
    </>
  )
}

export { navItems }

export default Header
